.Plans-container{
    margin-top: -4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
padding: 0 2rem;
position: relative;
}

.plans-header{
    color: white;
    display: flex;
    font-size: 3rem;
    gap: 4rem;
    font-weight: bold;
text-transform: uppercase;
font-style: italic;
justify-content: center;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard); 
   justify-content: center;
    color: white;
    padding: 1.5rem;
    gap: 2rem;
    width: 15rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
font-size: 1rem;
font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
    }
.plan>:nth-child(5){
        font-size: 0.8rem;
        }
.plan>:nth-child(5){
            font-size: 0.8rem;
            }
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.feature{
    display: flex;
align-items: center;
    gap: 1rem;
    
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
fill: white;    
}
.plans>:nth-child(2)>button{
    color: var(--orange);
}
.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}
.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);    
    background: red;
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);    
    background: rgb(255, 115, 0);
}
@media screen and (max-width: 768px)
{
    .plans-header{
        flex-direction: column;
        font-size: x-large;
         gap: 1rem;
         align-items: center;
         justify-content: center;
         margin-top: 3rem;
    }
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}