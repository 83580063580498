.header{

    display: flex;
   justify-content: space-between;
  
}
.Logo{
   
    width:10rem;
    height: 3rem;
}
.Header-menu{
   list-style: none;
   display: flex;
   gap: 2rem;
   color: white;
   font-weight: bold;
}

.Header-menu>li:hover{
    cursor: pointer;

color: var(--orange);

}
@media screen and (max-width: 768px)
{
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
.Header-menu{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
    font-weight: 400;
}


}